/* Position */

.ba-static {
    position: static;
}
.ba-fixed {
    position: fixed;
}
.ba-absolute {
    position: absolute;
}
.ba-relative {
	position: relative;
}
.ba-sticky {
	position: sticky;
}