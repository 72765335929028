/* Modals */

// Variables
$ba-modal-background-color: #00000080;
$ba-modal-z-index: 1000;

.ba-modal {
  // Initial state
  display: none;
  position: fixed;
  align-items: center;
  justify-content: center;
  inset: 0;
  z-index: $ba-modal-z-index;
  background-color: $ba-modal-background-color;
  
  // Active state
  &.is-active {
    display: flex;
    overflow: hidden;
  }

  // Background
  &-background {
    position: absolute;
    inset: 0;
    background-color: $ba-modal-background-color;
  }

  // Content
  &-content {
    display: flex;
    justify-content: center;
    padding: 1rem;
    border-radius: 8px;
  }

  // Card
  &-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 87vh;
    height: 55vh;
    gap: 16px;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px 32px 22px;
    position: relative;
    animation: ba-modal-opacity-animation 500ms;

    &-head {
      .ba-modal-card-title {
        display: block;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        margin: 0;
      }

      .ba-modal-close {
        position: absolute;
        right: 16px;
      }
    }

    &-body {
      display: block;
      border-radius: 8px;
      width: 100%;
      height: 100%;

      iframe {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: transparent;
        border: none;
      }
    }

    &-foot {
      .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
      }
    }
  }

  // Close button
  &-close {
    position: relative;
    right: 34px;
    top: 16px;
    width: 20px;
    height: 20px;
    background-color: #b4c4d7;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 50%;
      height: 2px; /* Thickness of the X */
      background-color: white; /* Color of the X */
      transform-origin: center;
      transition: background-color 0.3s;
    }

    &::before {
      transform: rotate(45deg); /* Diagonal line */
    }

    &::after {
      transform: rotate(-45deg); /* Oposite diagonal line */
    }
  }
}

@keyframes ba-modal-opacity-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}